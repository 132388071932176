<template>
  <div class="box">
    <div
      :class="list.image ? 'bgc' : 'havaImg'"
      :style="{ background: 'url(' + list.image + ')' }"
    ></div>
    <div :class="list.image ? 'content' : 'content1'">
      <div class="activityInformation">
        <div style="width: 100%; display: flex; justify-content: space-between">
          <div class="left">
            <p class="info_text">{{ list.title }}</p>
            <p class="time">
              投票时间：<span>{{ list.start_time }}-{{ list.end_time }}</span>
            </p>
          </div>
          <div class="right">
            <p class="grandTotal">累计投票</p>
            <p class="num">{{ list.total_votes }}</p>
          </div>
        </div>
        <div v-html="list.content" class="text-info"></div>
      </div>
      <div class="progress" v-for="item in list.option" :key="item.id">
        <div class="info">
          <p class="text">{{ item.title }}</p>
          <div class="container">
            <div
              class="skills"
              :style="{ width: item.scale * 100 + '%' }"
            ></div>
            <div class="area">面积:{{ item.area_total || "0" }}</div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="vote" v-if="!item.is_choiced" @click="Vote(item.id)">
            投票
          </div>
          <div class="votes" v-if="item.is_choiced">已投票</div>
          <div class="piao">
            <p>{{ item.votes }}</p>
            <p style="margin-left: 7px">
              {{ (item.scale * 100).toFixed(1) + "%" }}
            </p>
          </div>
        </div>
      </div>
      <div class="comeback" @click="comeback">
        <img src="../assets/back.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Detail, Vote } from "@/api/list";
export default {
  data() {
    return {
      isScroll: false,
      list: [],
    };
  },
  created() {
    if (!localStorage.getItem("access_id")) {
      this.$router.push("/");
    } else {
      this.getList();
    }
  },
  mounted() {},
  methods: {
    Vote(id) {
      console.log(id);
      Vote({ op_id: id }).then((res) => {
        if (res.data.code != 200)
          return this.$toast({ message: res.data.message });
        this.$toast({
          message: "投票成功",
        });
      });
      setTimeout(() => {
        this.getList();
      }, 200);
    },
    getList() {
      let data = {
        vote_id: this.$route.query.id,
      };
      Detail(data).then((res) => {
        if (res.data.code != 200) return;
        this.list = res.data.data;
        console.log(this.list);
      });
    },
    comeback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: calc(100vh - 80px);
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}
.text-info {
  /deep/ p {
    margin: 0;
    padding: 0;
  }
}
p {
  margin: 0;
  padding: 0;
}
.bgc {
  width: 100%;
  height: 300px;
  background: url("./../assets/bgc.png") no-repeat;
  background-size: 100%;
  .navbar {
    background: none;
    /deep/ .van-nav-bar__title {
      color: #ffffff;
    }
    /deep/ .van-icon {
      color: #ffffff;
    }
    /deep/ .van-nav-bar {
      background: none;
    }
  }
  .scrollNav {
    /deep/ .van-nav-bar__content {
      border-bottom: 1px solid #dcdcdc;
    }
    /deep/ .van-icon {
      color: #333333;
    }
    /deep/ .van-nav-bar__title {
      color: #333;
    }
  }
}
.havaImg {
  /deep/ .van-nav-bar__content {
    border-bottom: 1px solid #dcdcdc;
  }
  /deep/ .van-icon {
    color: #333333;
  }
  /deep/ .van-nav-bar__title {
    color: #333;
  }
}
.content {
  width: 100%;
  position: absolute;
  top: 251px;
  padding-bottom: 25px;
}
.content1 {
  margin-top: 10px;
  padding-bottom: 25px;
}
.activityInformation {
  width: 89%;
  // height: 190px;
  background: #ffffff;
  margin: auto;
  border-radius: 9px;
  box-shadow: 0px 2px 10px 0px rgba(111, 114, 250, 0.3);
  padding: 12px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .left {
    display: flex;
    flex-direction: column;
    .info_text {
      width: 260px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .time {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      margin-top: 6px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .grandTotal {
      font-size: 13px;
      font-weight: 400;
      color: #666666;
    }
    .num {
      color: #6f72fa;
      font-weight: bold;
      font-size: 22px;
      margin-top: 5px;
    }
  }
  .text-info {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }
}
.progress {
  height: 45px;
  background: #ffffff;
  margin: auto;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  .info {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    .text {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
    }
    .container {
      width: 100%;
      height: 9px;
      background-color: #f3f3f3;
      border-radius: 50px;
      .skills {
        width: 90%;
        height: 9px;
        border-radius: 50px;
        background-color: #d0d1ff;
      }
    }
  }
}
.vote {
  width: 70px;
  height: 25px;
  line-height: 25px;
  background: #6f72fa;
  border-radius: 28px;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
}
.votes {
  width: 70px;
  height: 25px;
  line-height: 25px;
  border-radius: 28px;
  background: #d0d1ff;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
}
.piao {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
}
@media screen and (max-width: 320px) {
  .content[data-v-68b3be6b] {
    top: 220px;
  }
  .info_text {
    width: 208px !important;
  }
}
@media (min-width: 414px) {
  .content[data-v-68b3be6b] {
    top: 280px;
  }
  .info_text {
    width: 290px !important;
  }
}
.comeback {
  position: fixed;
  bottom: 0;
  left: 20px;
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}
.area {
  font-size: 13px;
  color: #d0d1ff;
  margin-top: 3px;
}
</style>
